<template>
  <div>
    <el-form
      label-width="200px"
      :model="dataForm"
      :rules="rules"
      ref="dataForm"
    >
      <!-- 报名时间 -->
      <el-form-item label="报名时间" label-width="200px" required>
        <el-col :span="5">
          <el-form-item
            prop="match_time.sign_up_start"
            id="match_time.sign_up_start"
          >
            <el-date-picker
              :readonly="has_stage"
              ref="match_time_sign_up_start"
              type="datetime"
              placeholder="选择报名开始时间"
              v-model="dataForm.match_time.sign_up_start"
              style="width: 100%"
            ></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col class="line" :span="1" style="text-align: center">-</el-col>
        <el-col :span="5" id="match_time.sign_up_end">
          <el-form-item prop="match_time.sign_up_end">
            <el-date-picker
              :readonly="has_stage"
              ref="match_time_sign_up_end"
              type="datetime"
              placeholder="选择报名结束时间"
              v-model="dataForm.match_time.sign_up_end"
              style="width: 100%"
            ></el-date-picker>
          </el-form-item>
        </el-col>
      </el-form-item>
      <!-- 队伍管理时间 -->
      <el-form-item label="队伍管理时间" label-width="200px" required>
        <el-col :span="5">
          <el-form-item
            prop="match_time.team_startTime"
            id="match_time.team_startTime"
          >
            <el-date-picker
              :readonly="has_stage"
              ref="match_time_team_startTime"
              type="datetime"
              placeholder="选择队伍管理开始时间"
              v-model="dataForm.match_time.team_startTime"
              style="width: 100%"
            ></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col class="line" :span="1" style="text-align: center">-</el-col>
        <el-col :span="5" id="match_time.team_endTime">
          <el-form-item prop="match_time.team_endTime">
            <el-date-picker
              :readonly="has_stage"
              ref="match_time_team_endTime"
              type="datetime"
              placeholder="选择队伍管理结束时间"
              v-model="dataForm.match_time.team_endTime"
              style="width: 100%"
            ></el-date-picker>
          </el-form-item>
        </el-col>
      </el-form-item>
      <!-- 队伍审核时间 -->
      <el-form-item label="队伍审核时间" label-width="200px" required>
        <el-col :span="5">
          <el-form-item
            prop="match_time.revive_startTime"
            id="match_time.revive_endTime"
          >
            <el-date-picker
              :readonly="has_stage"
              ref="match_time_revive_startTime"
              type="datetime"
              placeholder="选择队伍审核开始时间"
              v-model="dataForm.match_time.revive_startTime"
              style="width: 100%"
            ></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col class="line" :span="1" style="text-align: center">-</el-col>
        <el-col :span="5" id="match_time.revive_endTime">
          <el-form-item prop="match_time.revive_endTime">
            <el-date-picker
              :readonly="has_stage"
              ref="match_time_revive_endTime"
              type="datetime"
              placeholder="选择队伍审核结束时间"
              v-model="dataForm.match_time.revive_endTime"
              style="width: 100%"
            ></el-date-picker>
          </el-form-item>
        </el-col>
      </el-form-item>
      <!-- 赛前分组时间 -->

      <!-- 比赛时间 -->
      <el-form-item label="比赛时间" required label-width="200px">
        <el-col :span="5">
          <el-form-item
            prop="match_time.match_start"
            id="match_time.match_start"
          >
            <el-date-picker
              :readonly="has_stage"
              ref="match_time_match_start"
              type="datetime"
              placeholder="选择比赛开始时间"
              v-model="dataForm.match_time.match_start"
              style="width: 100%"
            ></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col class="line" :span="1" style="text-align: center">-</el-col>
        <el-col :span="5" id="match_time.match_end">
          <el-form-item prop="match_time.match_end">
            <el-date-picker
              :readonly="has_stage"
              ref="match_time_match_end"
              type="datetime"
              placeholder="选择比赛结束时间"
              v-model="dataForm.match_time.match_end"
              style="width: 100%"
            ></el-date-picker>
          </el-form-item>
        </el-col>
      </el-form-item>
      <!-- 参赛门派 -->
      <span class="form-row">
        <el-form-item
          label="参赛门派"
          label-width="200px"
          style="margin-right: 10px"
          prop="join_sect_num"
          id="join_sect_num"
        >
          <el-input
            v-model="dataForm.join_sect_num"
            placeholder="请输入内容"
            style="flex-grow: 1; width: 200px"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="每组门派"
          label-width="200px"
          prop="group_sect_num"
          id="group_sect_num"
        >
          <el-input
            v-model="dataForm.group_sect_num"
            placeholder="请输入内容"
            style="flex-grow: 1; width: 200px"
          ></el-input>
        </el-form-item>
      </span>
      <!-- 晋级常规赛名额 -->
      <el-form-item
        label="晋级常规赛名额"
        label-width="200px"
        prop="qualifiers"
        id="qualifiers"
      >
        <el-input
          v-model="dataForm.qualifiers"
          placeholder="请输入内容"
          style="width: 200px"
        >
          <template slot="append">个</template>
        </el-input>
      </el-form-item>
      <span class="form-row">
        <!-- 报名长图 -->
        <el-form-item
          label="报名长图"
          label-width="200px"
          prop="sign_rule"
          id="Personalrewards_application"
        >
          <!-- 基于elementUi的上传组件 el-upload begin-->
          <el-upload
            class="avatar-uploader"
            :accept="'image/*'"
            :show-file-list="false"
            action="#"
            style="height: 0"
            :http-request="httpUploadEditorRequest_application"
          >
          </el-upload>
          <!-- 基于elementUi的上传组件 el-upload end-->
          <quill-editor
            class="editor ql-editor"
            v-model="dataForm.sign_rule"
            ref="customQuillEditorPrize_application"
            :options="editorOption__application"
          >
          </quill-editor>
        </el-form-item>
        <!-- 队伍管理规则 -->
        <el-form-item
          label="队伍管理规则"
          label-width="200px"
          prop="team_rule"
          id="quillEditorQiniu_team"
        >
          <!-- 基于elementUi的上传组件 el-upload begin-->
          <el-upload
            class="avatar-uploader"
            :accept="'image/*'"
            :show-file-list="false"
            action="#"
            style="height: 0"
            :http-request="httpUploadEditorRequest_team"
          >
          </el-upload>
          <!-- 基于elementUi的上传组件 el-upload end-->
          <quill-editor
            class="editor ql-editor"
            v-model="dataForm.team_rule"
            ref="quillEditorQiniu_team"
            :options="editorOption_team"
          >
          </quill-editor>
        </el-form-item>
        <!-- 预选赛规则  -->
        <el-form-item
          label="预选赛规则"
          label-width="200px"
          prop="rule"
          id="quillEditorQiniuPrize"
        >
          <!-- 基于elementUi的上传组件 el-upload begin-->
          <el-upload
            class="avatar-uploader"
            :accept="'image/*'"
            :show-file-list="false"
            action="#"
            style="height: 0"
            :http-request="httpUploadEditorRequest"
          >
          </el-upload>
          <!-- 基于elementUi的上传组件 el-upload end-->
          <quill-editor
            class="editor ql-editor"
            v-model="dataForm.rule"
            ref="customQuillEditorPrize"
            :options="editorOptionPrize"
          >
          </quill-editor>
        </el-form-item>
        <!-- 复活榜规则 -->
        <el-form-item
          label="复活榜规则"
          label-width="200px"
          prop="resurrection_rule"
          id="quillEditorQiniuPrizefuhuo"
        >
          <!-- 基于elementUi的上传组件 el-upload begin-->
          <el-upload
            class="avatar-uploader"
            :accept="'image/*'"
            :show-file-list="false"
            action="#"
            style="height: 0"
            :http-request="httpUploadEditorRequestfuhuo"
          >
          </el-upload>
          <!-- 基于elementUi的上传组件 el-upload end-->
          <quill-editor
            class="editor ql-editor"
            v-model="dataForm.resurrection_rule"
            ref="quillEditorQiniuPrizefuhuo"
            :options="editorOptionPrizefuhuo"
          >
          </quill-editor>
        </el-form-item>
      </span>

      <!-- 赛事二维码 -->
      <el-form-item
        v-if="dataForm.qr_code.length != 0"
        label=""
        id="qr_code"
        label-width="0px"
        prop="qr_code"
        required
      >
        <el-col
          :span="8"
          v-for="(item, index) in dataForm.qr_code"
          :key="item.group"
        >
          <el-form-item
            label="赛事微信二维码"
            :prop="`qr_code[${index}].code_picture`"
            :rules="
              dataForm.type_id == 'wpl_finals'
                ? rules.code_picture_no
                : rules.code_picture
            "
          >
            <el-upload
              class="avatar-uploader"
              :show-file-list="false"
              :before-upload="beforeTopImageUpload"
              action="#"
              drag
              :http-request="httpUploadRequest"
              :on-success="handleSuccess(item.group)"
            >
              <img
                v-if="item.code_picture"
                :src="item.code_picture"
                class="avatar"
              />
              <div v-else>
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">拖到此处，或<em>点击上传</em></div>
              </div>
            </el-upload>
          </el-form-item>
        </el-col>
      </el-form-item>
      <!-- 门派认证推文地址 url -->
     <el-form-item
        v-if="matchType != 'league'"
        id="ext.attestation"
        label-width="200px"
        label="门派认证推文地址"
        prop="attestation"
        style="margin-bottom: 35px"
      >
        <el-col :span="6">
          <el-input
            ref="ext_attestation"
            v-model="dataForm.attestation"
            placeholder="请输入门派认证推文地址"
          ></el-input>
        </el-col>
      </el-form-item>
      <!-- 提交 -->
      <el-form-item style="margin-top: 50px">
        <perButton
          type="primary"
          perm="armory:reporter:save"
          @click="submitForm()"
          size="large"
          >提交</perButton
        >
        <el-button size="large" @click="goBack()">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { uploadOssUrl } from '@/utils/uploadUrl'
import perButton from '@/components/perm/perButton'
import { randomStr } from '@/utils/randomStr'
import { mapActions, mapState } from 'vuex'
import { toolbarOptions } from '../../public.js/toolbarOptions'
export default {
  name: 'Basicinformation',
  components: {
    perButton,
  },
  props: {
    match_id: {
      type: String,
    },
    match_type: {
      type: String,
    },
    stage_id: {
      type: String,
    },
    view_type: {
      type: String,
    },
  },
  computed: {
    ...mapState('match', ['match_types']),
    isEdit: function() {
      if (this.view_type == 'edit') {
        return true
      }

      return false
    },
  },
  data() {
    // 初始化为当前日期的Date对象
    let self = this
    // 报名时间
    const signupEndTimeCheckRule = (rule, value, callback) => {
      if (
        self.dataForm.match_time.sign_up_start !== '' &&
        self.dataForm.match_time.sign_up_end != ''
      ) {
        let sign_up_start = new Date(self.dataForm.match_time.sign_up_start)
        let sign_up_end = new Date(self.dataForm.match_time.sign_up_end)
        if (sign_up_end <= sign_up_start) {
          callback(new Error('结束时间必须大于开始时间'))
        } else {
          callback()
        }
      } else {
        callback()
      }
    }
    // 队伍管理
    const team_endTimeCheckRule = (rule, value, callback) => {
      if (
        self.dataForm.match_time.team_startTime !== '' &&
        self.dataForm.match_time.team_endTime != ''
      ) {
        let team_startTime = new Date(self.dataForm.match_time.team_startTime)
        let team_endTime = new Date(self.dataForm.match_time.team_endTime)
        if (team_endTime <= team_startTime) {
          callback(new Error('结束时间必须大于开始时间'))
        } else {
          callback()
        }
      } else {
        callback()
      }
    }
    // 队伍审核
    const reviewEndTimeCheckRule = (rule, value, callback) => {
      if (
        self.dataForm.match_time.revive_startTime != '' &&
        self.dataForm.match_time.revive_endTime != ''
      ) {
        let revive_startTime = new Date(
          self.dataForm.match_time.revive_startTime
        )
        let revive_endTime = new Date(self.dataForm.match_time.revive_endTime)
        if (revive_endTime <= revive_startTime) {
          callback(new Error('结束时间必须大于开始时间'))
          return
        }
      }

      callback()
    }
    // 赛前分组
    const pre_endTimeCheckRule = (rule, value, callback) => {
      if (
        self.dataForm.match_time.pre_startTime != '' &&
        self.dataForm.match_time.pre_endTime != ''
      ) {
        let pre_startTime = new Date(self.dataForm.match_time.pre_startTime)
        let pre_endTime = new Date(self.dataForm.match_time.pre_endTime)
        if (pre_endTime <= pre_startTime) {
          callback(new Error('结束时间必须大于开始时间'))
          return
        }
      }

      callback()
    }

    const matchStartTimeCheckRule = (rule, value, callback) => {
      if (
        self.dataForm.match_time.review_end != '' &&
        self.dataForm.match_time.match_start != ''
      ) {
        let review_end = new Date(self.dataForm.match_time.review_end)
        let match_start = new Date(self.dataForm.match_time.match_start)
        if (match_start < review_end) {
          callback(new Error('比赛开始时间必须大于等于审核结束时间'))
          return
        }
      }

      callback()
    }

    const matchEndTimeCheckRule = (rule, value, callback) => {
      if (
        self.dataForm.match_time.match_start != null &&
        self.dataForm.match_time.match_end != null
      ) {
        let match_start = new Date(self.dataForm.match_time.match_start)
        let match_end = new Date(self.dataForm.match_time.match_end)
        if (match_end <= match_start) {
          callback(new Error('结束时间必须大于开始时间'))
          return
        }
      }

      callback()
    }

    return {
      dateValue: new Date(),
      matchType: '',
      type: 's1',
      has_stage: false,
      org_myprice: '',
      org_rule: '',
      org_prize: '',
      dataForm: {
        attestation :'',
        _id: '',
        // 预选赛规则
        rule: '',
        rule_url: '',
        type_id: 'qualifier',
        //队伍管理
        team_rule: '',
        team_rule_url: '',
        sign_rule: '',
        sign_rule_url: '',
        resurrection_rule: '',
        resurrection_rule_url: '',
        qualifiers: '', //晋级名额
        group_sect_num: '',
        join_sect_num: '',
        qr_code: [{ group: 1, code_picture: '' }],
        match_time: {
          sign_up_start: '',
          sign_up_end: '',
          match_start: '',
          match_end: '',
          pre_endTime: '',
          pre_startTime: '',
          reservationGift_endTime: '',
          reservationGift_startTime: '',
          revive_endTime: '',
          revive_startTime: '',
          team_endTime: '',
          team_startTime: '',
        },
      },
      rules: {
        qr_code: [
          {
            required: true,
            message: '请选择二维码图',
            trigger: ['change', 'blur'],
          },
        ],
        code_picture: [
          {
            required: true,
            message: '请选择二维码图',
            trigger: ['change', 'blur'],
          },
        ],
        code_picture_no: [
          {
            required: false,
            trigger: ['change', 'blur'],
          },
        ],
        rule: [
          {
            required: true,
            message: '请输入预选赛规则',
            trigger: ['blur', 'change'],
          },
        ],
        sign_rule: [
          {
            required: true,
            message: '请输入报名长图',
            trigger: ['blur', 'change'],
          },
        ],
        resurrection_rule: [
          {
            required: true,
            message: '请输入复活榜规则',
            trigger: ['blur', 'change'],
          },
        ],
        team_rule: [
          {
            required: true,
            message: '请输入队伍管理规则',
            trigger: ['blur', 'change'],
          },
        ],
        'match_time.sign_up_start': [
          {
            type: 'date',
            required: true,
            message: '请选择报名开始日期',
            trigger: ['change', 'blur'],
          },
        ],
        'match_time.team_startTime': [
          {
            type: 'date',
            required: true,
            message: '请选择队伍管理开始日期',
            trigger: ['change', 'blur'],
          },
        ],
        'match_time.revive_startTime': [
          {
            type: 'date',
            required: true,
            message: '请选择队伍审核开始日期',
            trigger: ['change', 'blur'],
          },
        ],
        'match_time.pre_startTime': [
          {
            type: 'date',
            required: true,
            message: '请选择赛前分组开始日期',
            trigger: ['change', 'blur'],
          },
        ],
        'ext.adv_img': [
          {
            required: true,
            message: '请选择报名宣传图',
            trigger: ['blur', 'change'],
          },
        ],
        join_sect_num: [
          {
            required: true,
            message: '请输入参赛门派数量',
            trigger: ['blur', 'change'],
          },
        ],
        attestation : [
          {
            required: true,
            message: '请输入门派认证推文地址',
            trigger: ['blur', 'change'],
          },
        ],
        group_sect_num: [
          {
            required: true,
            message: '请输入每组门派数量',
            trigger: ['blur', 'change'],
          },
        ],
        qualifiers: [
          {
            required: true,
            message: '请输入晋级常规赛名额',
            trigger: ['blur', 'change'],
          },
        ],
        'match_time.team_endTime': [
          {
            type: 'date',
            required: true,
            message: '请选择队伍管理结束日期',
            trigger: ['change', 'blur'],
          },
          { validator: team_endTimeCheckRule, trigger: ['change', 'blur'] },
        ],
        'match_time.sign_up_end': [
          {
            type: 'date',
            required: true,
            message: '请选择报名结束日期',
            trigger: ['change', 'blur'],
          },
          { validator: signupEndTimeCheckRule, trigger: ['change', 'blur'] },
        ],

        'match_time.pre_endTime': [
          {
            type: 'date',
            required: true,
            message: '请选择报名结束日期',
            trigger: ['change', 'blur'],
          },
          { validator: pre_endTimeCheckRule, trigger: ['change', 'blur'] },
        ],
        'match_time.revive_endTime': [
          {
            type: 'date',
            required: true,
            message: '请选择队伍审核结束日期',
            trigger: ['change', 'blur'],
          },
          { validator: reviewEndTimeCheckRule, trigger: ['change', 'blur'] },
        ],
        'match_time.match_start': [
          {
            type: 'date',
            required: true,
            message: '请选择比赛开始日期',
            trigger: ['change', 'blur'],
          },
          { validator: matchStartTimeCheckRule, trigger: ['change', 'blur'] },
        ],
        'match_time.match_end': [
          {
            type: 'date',
            required: true,
            message: '请选择比赛结束日期',
            trigger: ['change', 'blur'],
          },
          { validator: matchEndTimeCheckRule, trigger: ['change', 'blur'] },
        ],
      },
      //报名长图
      editorOption__application: {
        placeholder: '请填报名长图',
        modules: {
          imageResize: {
            displayStyles: {
              backgroundColor: 'black',
              border: 'none',
              color: 'white',
            },
            modules: ['Resize', 'DisplaySize', 'Toolbar'],
          },
          toolbar: {
            container: toolbarOptions, // 工具栏
            handlers: {
              size: function(value) {
                if (value === 'custom-size') {
                  value = prompt('请输入字体大小')
                  if (
                    !/^(([2-6][0-9])|([7][0-2])|([1][8-9]))(rpx)$/.test(value)
                  ) {
                    alert(
                      '请输入合法的字体大小，使用rpx单位， 大小在18rpx-72rpx之间, 1rpx=2px， 用于适配不同设备'
                    )
                    value = ''
                  }
                }

                this.quill.format('size', value)
              },
              color: function(value) {
                if (value === 'custom-color') {
                  value = prompt('请输入十六进制的颜色')
                  if (!/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/.test(value)) {
                    alert('请输入合法的十六进制颜色')
                    value = ''
                  }
                }

                this.quill.format('color', value)
              },
              image: function(value) {
                if (value) {
                  document
                    .querySelector(
                      '#Personalrewards_application .avatar-uploader input'
                    )
                    .click()
                } else {
                  this.quill.format('image', false)
                }
              },
              video: function(value) {
                if (value) {
                  document
                    .querySelector(
                      '#Personalrewards_application .avatar-uploader input'
                    )
                    .click()
                } else {
                  this.quill.format('video', false)
                }
              },
            },
          },
        },
      },
      // 队伍管理规则
      editorOption_team: {
        placeholder: '请填队伍管理规则',
        modules: {
          imageResize: {
            displayStyles: {
              backgroundColor: 'black',
              border: 'none',
              color: 'white',
            },
            modules: ['Resize', 'DisplaySize', 'Toolbar'],
          },
          toolbar: {
            container: toolbarOptions, // 工具栏
            handlers: {
              size: function(value) {
                if (value === 'custom-size') {
                  value = prompt('请输入字体大小')
                  if (
                    !/^(([2-6][0-9])|([7][0-2])|([1][8-9]))(rpx)$/.test(value)
                  ) {
                    alert(
                      '请输入合法的字体大小，使用rpx单位， 大小在18rpx-72rpx之间, 1rpx=2px， 用于适配不同设备'
                    )
                    value = ''
                  }
                }

                this.quill.format('size', value)
              },
              color: function(value) {
                if (value === 'custom-color') {
                  value = prompt('请输入十六进制的颜色')
                  if (!/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/.test(value)) {
                    alert('请输入合法的十六进制颜色')
                    value = ''
                  }
                }

                this.quill.format('color', value)
              },
              image: function(value) {
                if (value) {
                  document
                    .querySelector(
                      '#quillEditorQiniu_team .avatar-uploader input'
                    )
                    .click()
                } else {
                  this.quill.format('image', false)
                }
              },
              video: function(value) {
                if (value) {
                  document
                    .querySelector(
                      '#quillEditorQiniu_team .avatar-uploader input'
                    )
                    .click()
                } else {
                  this.quill.format('video', false)
                }
              },
            },
          },
        },
      },
      // 预选赛规则
      editorOptionPrize: {
        placeholder: '请填写预选赛奖励',
        modules: {
          imageResize: {
            displayStyles: {
              backgroundColor: 'black',
              border: 'none',
              color: 'white',
            },
            modules: ['Resize', 'DisplaySize', 'Toolbar'],
          },
          toolbar: {
            container: toolbarOptions, // 工具栏
            handlers: {
              size: function(value) {
                if (value === 'custom-size') {
                  value = prompt('请输入字体大小')
                  if (
                    !/^(([2-6][0-9])|([7][0-2])|([1][8-9]))(rpx)$/.test(value)
                  ) {
                    alert(
                      '请输入合法的字体大小，使用rpx单位， 大小在18rpx-72rpx之间, 1rpx=2px， 用于适配不同设备'
                    )
                    value = ''
                  }
                }

                this.quill.format('size', value)
              },
              color: function(value) {
                if (value === 'custom-color') {
                  value = prompt('请输入十六进制的颜色')
                  if (!/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/.test(value)) {
                    alert('请输入合法的十六进制颜色')
                    value = ''
                  }
                }

                this.quill.format('color', value)
              },
              image: function(value) {
                if (value) {
                  document
                    .querySelector(
                      '#quillEditorQiniuPrize .avatar-uploader input'
                    )
                    .click()
                } else {
                  this.quill.format('image', false)
                }
              },
              video: function(value) {
                if (value) {
                  document
                    .querySelector(
                      '#quillEditorQiniuPrize .avatar-uploader input'
                    )
                    .click()
                } else {
                  this.quill.format('video', false)
                }
              },
            },
          },
        },
      },
      //复活榜规则
      editorOptionPrizefuhuo: {
        placeholder: '请填写预选赛奖励',
        modules: {
          imageResize: {
            displayStyles: {
              backgroundColor: 'black',
              border: 'none',
              color: 'white',
            },
            modules: ['Resize', 'DisplaySize', 'Toolbar'],
          },
          toolbar: {
            container: toolbarOptions, // 工具栏
            handlers: {
              size: function(value) {
                if (value === 'custom-size') {
                  value = prompt('请输入字体大小')
                  if (
                    !/^(([2-6][0-9])|([7][0-2])|([1][8-9]))(rpx)$/.test(value)
                  ) {
                    alert(
                      '请输入合法的字体大小，使用rpx单位， 大小在18rpx-72rpx之间, 1rpx=2px， 用于适配不同设备'
                    )
                    value = ''
                  }
                }

                this.quill.format('size', value)
              },
              color: function(value) {
                if (value === 'custom-color') {
                  value = prompt('请输入十六进制的颜色')
                  if (!/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/.test(value)) {
                    alert('请输入合法的十六进制颜色')
                    value = ''
                  }
                }

                this.quill.format('color', value)
              },
              image: function(value) {
                if (value) {
                  document
                    .querySelector(
                      '#quillEditorQiniuPrizefuhuo .avatar-uploader input'
                    )
                    .click()
                } else {
                  this.quill.format('image', false)
                }
              },
              video: function(value) {
                if (value) {
                  document
                    .querySelector(
                      '#quillEditorQiniuPrizefuhuo .avatar-uploader input'
                    )
                    .click()
                } else {
                  this.quill.format('video', false)
                }
              },
            },
          },
        },
      },
      loading: false,
    }
  },
  watch: {
    match_data: {
      handler: function(val) {
        // this.setFormData();
      },
      immediate: true,
    },
  },
  // 编辑回显
  async beforeMount() {
    if (this.view_type == 'edit') {
      let res = await this.$http.match_subject.match_detail_stage(this.stage_id)
      if (res.code == 0) {
        this.dataForm.attestation = res.data.attestation
        this.dataForm.group_sect_num = res.data.group_sect_num
        this.dataForm.join_sect_num = res.data.join_sect_num
        this.dataForm.qr_code = res.data.qr_code
        this.dataForm.qualifiers = res.data.qualifiers
        //富文本
        this.dataForm.team_rule = res.data.team_rule.rule
        this.dataForm.sign_rule = res.data.sign_rule.rule
        this.dataForm.rule = res.data.rule.rule
        this.dataForm.resurrection_rule = res.data.resurrection_rule.rule
        //时间
        this.dataForm.match_time.sign_up_start = new Date(
          res.data.match_time.sign_up_start
        )
        this.dataForm.match_time.sign_up_end = new Date(
          res.data.match_time.sign_up_end
        )
        this.dataForm.match_time.match_start = new Date(
          res.data.match_time.match_start
        )
        this.dataForm.match_time.match_end = new Date(
          res.data.match_time.match_end
        )
        if (this.dataForm.match_time.pre_startTime != '') {
             this.dataForm.match_time.pre_startTime = new Date(
          res.data.match_time.pre_startTime
        )
        }
        if (this.dataForm.match_time.pre_endTime != '') {
           this.dataForm.match_time.pre_endTime = new Date(
          res.data.match_time.pre_endTime
        )
        }
        this.dataForm.match_time.revive_startTime = new Date(
          res.data.match_time.revive_startTime
        )
        this.dataForm.match_time.revive_endTime = new Date(
          res.data.match_time.revive_endTime
        )
        this.dataForm.match_time.team_startTime = new Date(
          res.data.match_time.team_startTime
        )
        this.dataForm.match_time.team_endTime = new Date(
          res.data.match_time.team_endTime
        )
        this.dataForm._id = res.data._id
        // 确保DOM已更新
        this.$nextTick(() => {
          // 使用ref滚动到预选赛规则表单项
          if (this.$refs.dataForm) {
            this.$refs.dataForm.$el.scrollIntoView({
              behavior: 'smooth',
              block: 'start',
            })
          }
        })
      }
    }
  },
  methods: {
    ...mapActions('tabs', ['removeTabByPath']),
    //返回上一次
    goBack() {
      this.$router.push({
        path: `/match/subject/list`,
        query: {
          match_id: this.match_id,
          match_type: this.match_type,
        },
      })
      this.removeTabByPath(`/match/subject/match_segment`)
    },
    //提交
    submitForm() {
      this.$refs.dataForm.validate(async (valid, invalidFields) => {
        if (valid) {
          let d = this.getSaveData()
          console.log('🚀 ~ this.$refs.dataForm.validate ~ d:', d)
       
          

          //预选赛规则
          let rule_url = await this.uploadContent('rule', this.dataForm.rule)
          if (!rule_url || !rule_url.length) {
            this.$message.error('保存失败, 上传预选赛规则失败')
            return
          }
          // 队伍管理规则
          let team_rule = await this.uploadContent(
            'team_rule',
            this.dataForm.team_rule
          )
          if (!team_rule || !team_rule.length) {
            this.$message.error('保存失败, 上传队伍管理规则失败')
            return
          }
          // 报名长图
          let sign_rule = await this.uploadContent(
            'sign_rule',
            this.dataForm.sign_rule
          )
          if (!sign_rule || !sign_rule.length) {
            this.$message.error('保存失败, 上传报名长图失败')
            return
          }
          //复活榜规则
          let resurrection_rule = await this.uploadContent(
            'resurrection_rule',
            this.dataForm.resurrection_rule
          )
          if (!resurrection_rule || !resurrection_rule.length) {
            this.$message.error('保存失败, 上传报名长图失败')
            return
          }
          d.resurrection_rule_url = resurrection_rule
          d.sign_rule_url = sign_rule
          d.team_rule_url = team_rule
          d.rule_url = rule_url
          d.season_name = '预选赛'
          d.match_id = this.match_id
          let {
            data,
            errorCode,
          } = await this.$http.match_subject.save_subject_season(d)
          if (errorCode != '0000') {
            this.$message.error('保存失败')
            return
          }
          this.$message({
            type: 'success',
            message: '保存成功',
          })
          this.removeTabByPath(`/match/subject/managerLeague`)
          this.$router.push({
            path: `/match/subject/list`,
            query: {
              match_id: this.match_id,
              match_type: this.match_type,
            },
          })
          this.goBack()
          this.$bus.$emit('onMatchChanged', data)
        } else {
          let fileds = Object.keys(invalidFields)
          if (fileds.length) {
            let id1 = fileds[0]
            if (id1 == 'desc') {
              id1 = 'quillEditorQiniu'
            }

            if (document.getElementById(id1)) {
              document
                .getElementById(id1)
                .scrollIntoView({ behavior: 'smooth' })
            }

            let refKey = id1.replace(/\./g, '_')
            if (this.$refs[refKey]) {
              this.$refs[refKey].focus()
            }
          }
          return false
        }
      })
    },

    getSaveData() {
      let data = {
        ...this.dataForm,
      }

      return data
    },

    resetForm() {
      this.$refs.dataForm.resetFields()
    },
    // 上传图片
    beforeTopImageUpload(file) {
      const isJPG = file.type === 'image/jpeg' || file.type === 'image/png'
      const isLt2M = file.size / 1024 / 1024 < 20

      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG/PNG 格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 20MB!')
      }

      return isJPG && isLt2M
    },
    async httpUploadRequest(item) {
      console.log(item)
      this.loading = true
      let fileName = randomStr() + '.jpg'
      let path = uploadOssUrl().notice + fileName
      let result = await this.$http.uploadFile(path, fileName, item.file)
      if (result) {
        this.imgCode = result
      } else {
        this.$message.error('上传失败')
      }
      this.loading = false
    },
    handleSuccess(gruop) {
      return (response, file, fileList) => {
        // 根据索引处理上传成功后的逻辑

        this.dataForm.qr_code[gruop - 1].code_picture = this.imgCode
        // 其他处理逻辑
      }
    },
    //预选赛规则
    async httpUploadEditorRequest(item) {
      this.loading = true
      let fileName = randomStr() + '.jpg'
      let path = uploadOssUrl().notice + fileName
      let result = await this.$http.uploadFile(path, fileName, item.file)
      if (result) {
        console.log('上传成功')
        // this.$emit('upload',res, file)
        // console.log(res, file);
        //拼接出上传的图片在服务器的完整地址
        let imgUrl = result
        let type = imgUrl.substring(imgUrl.lastIndexOf('.') + 1)
        console.log(type)
        // 获取富文本组件实例
        let quill = this.$refs.customQuillEditorPrize.quill
        // 获取光标所在位置
        let length = quill.getSelection().index
        // 插入图片||视频  res.info为服务器返回的图片地址
        if (type == 'mp4' || type == 'MP4') {
          quill.insertEmbed(length, 'video', imgUrl)
        } else {
          quill.insertEmbed(length, 'image', imgUrl)
        }
        // 调整光标到最后
        quill.setSelection(length + 1)
        //取消上传动画
        this.quillUpdateImg = false
      } else {
        this.$message.error('上传失败')
      }

      this.loading = false
    },
    // 报名长图
    async httpUploadEditorRequest_application(item) {
      this.loading = true
      let fileName = randomStr() + '.jpg'
      let path = uploadOssUrl().notice + fileName
      let result = await this.$http.uploadFile(path, fileName, item.file)
      if (result) {
        console.log('上传成功')
        // this.$emit('upload',res, file)
        // console.log(res, file);
        //拼接出上传的图片在服务器的完整地址
        let imgUrl = result
        let type = imgUrl.substring(imgUrl.lastIndexOf('.') + 1)
        console.log(type)
        // 获取富文本组件实例
        let quill = this.$refs.customQuillEditorPrize_application.quill
        // 获取光标所在位置
        let length = quill.getSelection().index
        // 插入图片||视频  res.info为服务器返回的图片地址
        if (type == 'mp4' || type == 'MP4') {
          quill.insertEmbed(length, 'video', imgUrl)
        } else {
          quill.insertEmbed(length, 'image', imgUrl)
        }
        // 调整光标到最后
        quill.setSelection(length + 1)
        //取消上传动画
        this.quillUpdateImg = false
      } else {
        this.$message.error('上传失败')
      }

      this.loading = false
    },
    // 队伍管理规则
    async httpUploadEditorRequest_team(item) {
      this.loading = true
      let fileName = randomStr() + '.jpg'
      let path = uploadOssUrl().notice + fileName
      let result = await this.$http.uploadFile(path, fileName, item.file)
      if (result) {
        console.log('上传成功')
        // this.$emit('upload',res, file)
        // console.log(res, file);
        //拼接出上传的图片在服务器的完整地址
        let imgUrl = result
        let type = imgUrl.substring(imgUrl.lastIndexOf('.') + 1)
        console.log(type)
        // 获取富文本组件实例
        let quill = this.$refs.quillEditorQiniu_team.quill
        // 获取光标所在位置
        let length = quill.getSelection().index
        // 插入图片||视频  res.info为服务器返回的图片地址
        if (type == 'mp4' || type == 'MP4') {
          quill.insertEmbed(length, 'video', imgUrl)
        } else {
          quill.insertEmbed(length, 'image', imgUrl)
        }
        // 调整光标到最后
        quill.setSelection(length + 1)
        //取消上传动画
        this.quillUpdateImg = false
      } else {
        this.$message.error('上传失败')
      }

      this.loading = false
    },
    // 复活榜规则
    async httpUploadEditorRequestfuhuo(item) {
      this.loading = true
      let fileName = randomStr() + '.jpg'
      let path = uploadOssUrl().notice + fileName
      let result = await this.$http.uploadFile(path, fileName, item.file)
      if (result) {
        //拼接出上传的图片在服务器的完整地址
        let imgUrl = result
        let type = imgUrl.substring(imgUrl.lastIndexOf('.') + 1)
        console.log(type)
        // 获取富文本组件实例
        let quill = this.$refs.quillEditorQiniuPrizefuhuo.quill
        // 获取光标所在位置
        let length = quill.getSelection().index
        // 插入图片||视频  res.info为服务器返回的图片地址
        if (type == 'mp4' || type == 'MP4') {
          quill.insertEmbed(length, 'video', imgUrl)
        } else {
          quill.insertEmbed(length, 'image', imgUrl)
        }
        // 调整光标到最后
        quill.setSelection(length + 1)
        //上传动画
        this.quillUpdateImg = true
      } else {
        this.$message.error('上传失败')
      }
      this.loading = false
    },
    // 转rule_url
    async uploadContent(t, d) {
      let blob = new Blob([d], { type: 'text/html;charset=utf-8' })

      // 这么写是因为文件转换是异步任务
      let transToFile = async (blob, fileName, fileType) => {
        return new window.File([blob], fileName, {
          type: fileType,
          charset: 'utf-8',
        })
      }

      let fileName = t + '_' + randomStr() + '.html'
      let textContain = transToFile(blob, fileName, 'application/json')

      // 转换完成后可以将file对象传给接口
      return await new Promise((resolve, reject) => {
        textContain
          .then(async (res) => {
            let file = res
            let path = uploadOssUrl().richText + this.type + '/' + fileName
            console.log(path)
            let result = await this.$http.uploadFile(path, fileName, file)
            resolve(result)
          })
          .catch(() => {
            resolve(null)
          })
      })
    },
    cancelDialog() {},
    ...mapActions('tabs', [
      'handleTabsEdit',
      'addTabs',
      'handleTabsClick',
      'removeTab',
      'removeTabByPath',
    ]),
  },
}
</script>
<style lang="less" scoped>
.editor {
  line-height: normal !important;
  height: fit-content;
  width: 375px;
  padding: 0 !important;
}
.form-row {
  display: flex;
  flex-wrap: wrap;
  padding: 0 10px;
}

/deep/.editor .ql-editor {
  height: 667px;
}

/deep/ .el-form-item__label {
  width: 180px !important;
}

/deep/ .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
/deep/ .avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
/deep/ .avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
/deep/ .el-upload-dragger {
  width: 178px;
  height: 178px;
}
/deep/ .avatar {
  width: 178px;
  height: 178px;
  display: block;
}

/deep/ .el-upload-dragger {
  text-align: center;
}

/deep/ .el-upload-dragger img {
  margin: auto;
}

/deep/ .ql-picker-item[data-value='custom-color'] {
  background: transparent !important;
  background-color: transparent !important;
  width: fit-content;
  height: fit-content;
}

/deep/ .ql-picker-item[data-value='custom-color']::before {
  content: '自定义颜色';
  color: black;
  width: fit-content;
}
</style>
